import React from 'react';
import {ConfigProvider, Flex, Layout, Card} from 'antd';
import {Link} from "react-router-dom";

function MainHome() {
    const { Meta } = Card;

    return (

            <Layout style={{ minHeight: '100vh' }}>
                <ConfigProvider>
                    <br/><br/><br/><br/>
                    <Flex justify="center" wrap gap={20}
                          style={{width: '100%',maxWidth: '95%', margin: '0 auto'}}> {/* 添加 justify="space-between" 和 wrap */}
                        <a href={'https://www.gui.ink'} target="_blank" rel="noopener noreferrer">
                            <Card
                                hoverable
                                style={{width: 320, height: 320}}
                                cover={<img alt="gui-ink" src={'List/pexels-brett-sayles-2881233.jpg'}/>}
                            >
                                <Meta title="My Servers Status Monitor" description="www.gui.ink"/>
                            </Card>
                        </a>
                        <a href={'https://www.pblaze.com'} target="_blank" rel="noopener noreferrer">
                            <Card
                                hoverable
                                style={{width: 320, height: 320}}
                                cover={<img alt="pblaze-com" src={'List/pexels-nanadua11-8622911.jpg'}/>}
                            >
                                <Meta title="Techonical News" description="www.pblaze.com"/>
                            </Card>
                        </a>
                        <a href={'https://blog.pblaze.com'} target="_blank" rel="noopener noreferrer">
                            <Card
                                hoverable
                                style={{width: 320, height: 320}}
                                cover={<img alt="example" src={'List/pexels-matthardy-1533720.jpg'}/>}
                            >
                                <Meta title="My Blogs" description="blog.pblaze.com"/>
                            </Card>
                        </a>
                    </Flex>
                    <br/>
                    <Flex justify="center" wrap gap={20}
                          style={{width: '100%'}}> {/* 添加 justify="space-between" 和 wrap */}
                        <Link to={"/Tools"}>
                            <Card
                                hoverable
                                style={{width: 320, height: 320}}
                                cover={<img alt="tools" src={'List/pexels-padrinan-3930091.jpg'}/>}
                            >
                                <Meta title="One More Thing" description=""/>
                            </Card>
                        </Link>
                    </Flex>
                    <Flex justify="center" wrap gap={20}
                          style={{width: '100%'}}> {/* 添加 justify="space-between" 和 wrap */}
                    </Flex>
                </ConfigProvider>
            </Layout>

)
}
export default MainHome;