import React, {useEffect, useState} from 'react';
import { useTrail, a } from '@react-spring/web';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';

const Trail: React.FC<{ open: boolean; children: React.ReactNode }> = ({ open, children }) => {
    const items = React.Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 2000, friction: 200 },
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        height: open ? 110 : 0,
        from: { opacity: 0, x: 20, height: 0 },
    });
    return (
        <div>
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} className={styles.trailsText} style={style}>
                    <a.span style={{ height }}>{items[index]}</a.span> {/* 关键修改：使用 a.span 包裹 items[index] */}
                </a.div>
            ))}
        </div>
    );
};

export default function SayHello() {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const goBack = () => {
        navigate('/Home');
    }
    useEffect(() => {
        //More robust mobile detection using user agent
        const userAgent = navigator.userAgent || navigator.vendor ;
        if (/android|webos|iphone|ipad|ipod|blackberry|windows phone/i.test(userAgent)) {
            setIsMobile(true);
        }
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500); // 模拟加载 1.5 秒
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!isLoading) {
            setOpen(false);
            navigate('/Home');
        }
    }, [isLoading, navigate]);

    return (

        <div className={`${styles.container} ${isMobile ? styles.mobileTrailsText : ''}`} onClick={goBack}>

                {isLoading && (
                    <Trail open={open}>
                        <span>Welcome</span>
                        <span>to</span>
                        <span>KaliJerry</span>
                        <span>Nav Page</span>
                        <span>🥳🥳🥳</span>
                    </Trail>
                )}
            {!isLoading && <></>}
        </div>

    );
}
// eslint-disable-next-line no-lone-blocks
{/*This Code sources from https://codesandbox.io/s/yps54*/}