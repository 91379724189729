import React from "react";
import MainButtonGroup from  './Button/Buttons'
function BottomNav() {

    return (
        <header className="main-nav-wrapper">
            <div>
                <MainButtonGroup/>
            </div>
        </header>
    )
}

export default BottomNav;

