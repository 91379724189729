import React from 'react';
import { FloatButton } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
    HomeOutlined,
    RollbackOutlined
} from '@ant-design/icons';

function HomeButton() {
    return (
        <FloatButton href="/Home" icon={<HomeOutlined />} />
    );
}

function BackButton() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <FloatButton onClick={goBack} icon={<RollbackOutlined />} />
    );
}

function MainButtonGroup() {

    interface ButtonContainerStyle {
        position: 'fixed';
        right: string;
        zIndex: number;
    }

    const buttonContainerStyle: ButtonContainerStyle = {
        position: 'fixed',
        right: '20px',
        zIndex: 1000,
    };

    return (
        <div style={buttonContainerStyle}>
            <FloatButton.Group placement="right" >
                <HomeButton />
                <BackButton />
            </FloatButton.Group>
        </div>
    );
}

export default MainButtonGroup;