import React from 'react';
import {ConfigProvider, Layout} from 'antd';
const { Footer } = Layout;
function MainFooter() {
    return (
        <Layout>
            <ConfigProvider>
            <Footer style={{ textAlign: 'center' }}>
                KaliJerry's Nav ©{new Date().getFullYear()} Created by KaliJerry
            </Footer>
            </ConfigProvider>
        </Layout>
    )
}
export default MainFooter;