import React from 'react';
import { Card, ConfigProvider, Flex, Layout, theme } from 'antd';
import { Link } from 'react-router-dom';
import BottomNav from "../Main/BottomNav";

function MainTools() {
    const { Meta } = Card;

    return (
        <div className="MainTools">
            <BottomNav/>
            <Layout style={{ minHeight: '100vh', backgroundColor: '#000000' }}> {/* Set background here */}
                <ConfigProvider
                    theme={{
                        algorithm: theme.darkAlgorithm,
                        token: {
                            colorPrimary: '#000000', // Optional: Customize primary color if needed

                            colorText: '#fff', // Important: Set text color
                        },
                        components: {
                            Layout: {
                            },
                        },
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', padding: '20px' }}> {/* Add padding */}
                        <Flex justify="center" wrap gap={20} style={{ width: '100%' }}>
                            <Link to="/CardsStack">
                                <Card
                                    hoverable
                                    style={{ width: 500, height: 500, boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)' }}
                                    cover={<img alt="Cards" src={'List/pexels-pixabay-262333.jpg'} />}
                                >
                                    <Meta title="Play Cards" description="A react-spring demo" />
                                </Card>
                            </Link>
                        </Flex>
                    </div>
                </ConfigProvider>
            </Layout>
        </div>
    );
}

export default MainTools;