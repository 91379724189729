import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './App.css';
import SayHello from "./templates/Main/SayHello/SayHello";
import CardsStack from "./templates/Games/CardsStack/CardsStack";
import Loading from "./templates/Main/Loading/Loading";
import MainHome from  "./templates/Main/Home/MainHome";
import MainFooter from  "./templates/Main/Footer/MainFooter";
import {ConfigProvider, Layout, theme} from "antd";
import MainTools from "./templates/Tools/MainTools";


function Main(){

    return (
            <Layout>
            <ConfigProvider   theme={{
                algorithm: theme.darkAlgorithm,
                components: {
                    Layout: {

                    },
                },
            }}>
                    <MainHome/>
                    <MainFooter/>
                </ConfigProvider>
            </Layout>
    )
}

function App() {
    return (
        <BrowserRouter>
            <div className="container">
                <Routes>
                    <Route path="/Loading" element={<Loading/>}/>
                    <Route path="/Home" element={<Main />} />
                    <Route path="/CardsStack" element={<CardsStack />} />
                    <Route path="/Tools" element={<MainTools />} />
                    <Route path="/" element={<SayHello />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
